import React from "react";

interface IAddressRow {
    address: IAddress;
    slim: boolean;
}

export const AddressRow = ({ address, slim = false }: IAddressRow) => {
    return (
        <div>
            {!slim && (
                <div>
                    {address.address_type === "billing" ? "Rechnungsadresse" : "Sitz"} —{" "}
                    {address.contact}
                    <br />
                    {address.extension}
                    {(address.billing_contact || address.billing_email) && <br />}
                    {address.billing_contact && (
                        <div>{"Ansprechpartner für Rechnungen"} — {address.billing_contact}</div>
                    )}
                    {address.billing_email && (
                        <div>{"E-Mail-Adresse für Rechnungen"} — {address.billing_email}</div>
                    )}
                    {(address.billing_contact || address.billing_email) && <br />}
                </div>
            )}
            <div>{address.street}</div>
            <div>
                {address.postalcode} {address.city}
                <br />
                {address.country}
            </div>
        </div>
    );
};
